import styled from 'styled-components';
import tw from 'tailwind.macro';

export const Form = styled.form`
    ${tw`w-full sm:w-3/4 m-auto`}

    & .grid-container {
        ${tw`flex flex-col`}
    }

    & .grid-item {
        ${tw`my-4`}
    }

    & label {
        ${tw`block`}
    }

    & label > span {
        ${tw`text-gray-700`}
    }

    & input,
    & textarea {
        ${tw`mt-1 block w-full`}
        appearance: none;
        background-color: #fff;
        border-color: rgb(176 177 181);
        border-width: 1px;
        border-radius: 0.375rem;
        padding-top: 0.5rem;
        padding-right: 0.75rem;
        padding-bottom: 0.5rem;
        padding-left: 0.75rem;
        font-size: 1rem;
        line-height: 1.5rem;
    }

    & input:focus,
    & textarea:focus {
        border-color: #b4ccff;
        outline: 2px solid transparent;
        outline-offset: 2px;
        box-shadow: 0px 0px 4px 1px #b4ccff;
    }
`;

export const EmailSection = styled.div`
    ${tw`w-full sm:w-3/4 m-auto`}

    & .text {
        ${tw`text-gray-600`}
    }
`;

export const Or = styled.h2`
    line-height: 0.1em;
    ${tw`w-full text-center border-b border-gray-500 my-6`}

    & span {
        ${tw`bg-white px-2 bg-white text-gray-500`}
    }
`;
