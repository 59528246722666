import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import SEO, { SeoFrontmatter } from 'components/SEO';
import ContactInfo, { ContactInfoFrontmatter } from 'components/ContactInfo';
import locales from 'constants/locales';

interface Props {
    pageContext: { locale: keyof typeof locales };
    data: {
        contact: { frontmatter: ContactInfoFrontmatter };
        seo: {
            frontmatter: SeoFrontmatter;
        };
    };
}

const ContactPage: React.FC<Props> = ({ pageContext: { locale }, data: { seo, contact } }) => {
    return (
        <Layout locale={locale}>
            <SEO
                path="contact"
                title={seo.frontmatter.title}
                locale={seo.frontmatter.locale}
                description={seo.frontmatter.description}
            />
            <ContactInfo data={contact} />
        </Layout>
    );
};

export const query = graphql`
    query Contact($locale: String!) {
        seo: markdownRemark(
            frontmatter: { category: { eq: "seo-contact" }, locale: { eq: $locale } }
        ) {
            frontmatter {
                title
                description
                locale
            }
        }
        contact: markdownRemark(
            frontmatter: { category: { eq: "contact section" }, locale: { eq: $locale } }
        ) {
            frontmatter {
                title
                subtitle
                formApiUrl
                contactFormFields {
                    name {
                        label
                    }
                    email {
                        label
                    }
                    message {
                        label
                    }
                    submitButton {
                        label
                    }
                }
                orLabel
                dropEmailMessage
                submissionMessages {
                    success
                    fail
                }
            }
        }
    }
`;

export default ContactPage;
