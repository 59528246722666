/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { useCallback, useState } from 'react';
import cogoToast from 'cogo-toast';

import Container from 'components/ui/Container';
import TitleSection from 'components/ui/TitleSection';

import Button from 'components/ui/Button';
import * as Styled from './styles';

export interface ContactInfoFrontmatter {
    title: string;
    subtitle: string;
    formApiUrl: string;
    contactFormFields: {
        name: { label: string };
        email: { label: string };
        message: { label: string };
        submitButton: { label: string };
    };
    orLabel: string;
    dropEmailMessage: string;
    submissionMessages: {
        success: string;
        fail: string;
    };
}

interface Props {
    data: {
        frontmatter: ContactInfoFrontmatter;
    };
}

const ConctactInfo: React.FC<Props> = ({ data }) => {
    const { frontmatter: section } = data;
    const [formState, setFormState] = useState({
        loading: false,
        error: null,
        name: '',
        email: '',
        message: '',
    });

    const { name, email, message, loading } = formState;

    const setFormField = useCallback(
        (field, value) => setFormState((state) => ({ ...state, [field]: value })),
        []
    );

    const handleSubmit = async () => {
        // eslint-disable-next-line @typescript-eslint/non-nullable-type-assertion-style
        const myForm = document.querySelector('#tasoskakour-contact-form') as HTMLFormElement;
        if (myForm?.checkValidity()) {
            try {
                setFormState((s) => ({ ...s, loading: true }));

                const response = await fetch(section.formApiUrl, {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        name,
                        email,
                        message,
                    }),
                });
                if (response.ok) {
                    cogoToast.success(section.submissionMessages.success, {
                        hideAfter: 3,
                        position: 'top-center',
                        toastContainerID: 'notification-container',
                    });
                } else {
                    cogoToast.error(section.submissionMessages.fail, {
                        hideAfter: 6,
                        position: 'top-center',
                        toastContainerID: 'notification-container',
                    });
                }
            } catch {
                cogoToast.error(section.submissionMessages.fail, {
                    hideAfter: 6,
                    position: 'top-center',
                    toastContainerID: 'notification-container',
                });
            } finally {
                setFormState((s) => ({ ...s, loading: false }));
            }
        }
    };

    return (
        <Container>
            <TitleSection center title={section.title} subtitle={section.subtitle} />
            <Styled.Form id="tasoskakour-contact-form" onSubmit={(event) => event.preventDefault()}>
                <div id="toast-container" />
                <div className="grid-container">
                    <div className="grid-item">
                        <label>
                            <span>{section.contactFormFields.name.label}*</span>
                            <input
                                required
                                type="text"
                                onChange={(event) => setFormField('name', event.target.value)}
                            />
                        </label>
                    </div>
                    <div className="grid-item">
                        <label>
                            <span>{section.contactFormFields.email.label}*</span>
                            <input
                                required
                                type="email"
                                onChange={(event) => setFormField('email', event.target.value)}
                            />
                        </label>
                    </div>
                    <div className="grid-item">
                        <label>
                            <span>{section.contactFormFields.message.label}*</span>
                            <textarea
                                required
                                rows={3}
                                onChange={(event) => setFormField('message', event.target.value)}
                            />
                        </label>
                    </div>
                    <div className="grid-item">
                        <Button
                            primary
                            loading={loading}
                            disabled={loading}
                            onClick={async () => handleSubmit()}
                        >
                            {section.contactFormFields.submitButton.label}
                        </Button>
                    </div>
                </div>
            </Styled.Form>
            <Styled.EmailSection>
                <Styled.Or>
                    <span>{section.orLabel}</span>
                </Styled.Or>
                <div className="text">
                    {section.dropEmailMessage}:{' '}
                    <a href="mailto:tasoskakour@gmail.com">tasoskakour@gmail.com</a>
                </div>
            </Styled.EmailSection>
        </Container>
    );
};

export default ConctactInfo;
